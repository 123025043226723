import * as odataService from '@/core/common/services/odata.service';
import apiService from '@/core/common/services/api.service';
import { fernRegions, euRegions } from '@/core/common/helpers/utils.js';

import { convertTableSortToOdata } from '@/core/common/helpers/odataUtils';
import { getReisenOfRegionsmanager } from '../../common/helpers/utils';
import { add } from 'date-fns';
export const SET_FLUGVERFUEBARKEIT_KUERZEL_FILTER = 'flugverfuegbarkeit.setReiseterminKuerzelFilter';
export const RESET_FLUGVERFUEBARKEIT_KUERZEL_FILTER = 'flugverfuegbarkeit.resetReiseterminKuerzelFilter';

export const GET_FLUGVERFUEGBARKEIT = 'flugverfuegbarkeit.getFlugverfuebarkeit';
export const SET_FLUGVERFUEGBARKEIT = 'flugverfuegbarkeit.setFlugverfuebarkeit';
export const APPEND_FLUGVERFUEGBARKEIT = 'flugverfuegbarkeit.appendFlugverfuebarkeit';
export const SET_FLUGVERFUEGBARKEIT_TOP = 'flugverfuegbarkeit.setFlugverfuebarkeitTop';
export const SET_FLUGVERFUEGBARKEIT_SKIP = 'flugverfuegbarkeit.setFlugverfuebarkeitSkip';
export const SET_FLUGVERFUEGBARKEIT_ORDER_BY = 'flugverfuegbarkeit.setFlugverfuebarkeitOrderBy';
export const SET_FLUGVERFUEGBARKEIT_IS_BUSY = 'flugverfuegbarkeit.setFlugverfuebarkeitIsBusy';
export const SET_FLUGVERFUEGBARKEIT_COUNT = 'flugverfuegbarkeit.setFlugverfuebarkeitCount';
export const SET_IS_INITIALLY_LOADING = 'flugverfuegbarkeit.setIsInitiallyLoading';
export const SET_LAST_ABFRAGE_IS_LOADING = 'flugverfuegbarkeit.setIsLastAbfrageLoading';
export const SET_FLUGPLANUNGSDATEN_IS_LOADING = 'flugverfuegbarkeit.setIsFlugplanungsdatenLoading';

export const FLUGVERFUEGBARKEIT_SORT_CHANGED = 'flugverfuegbarkeit.sortChanged';

export const RESET_FILTER = 'flugverfuegbarkeit.resetFilter';

export const SET_EDITED_FILTER = 'flugverfuegbarkeit.setEditedFilter';
export const RESET_EDITED_FILTER = 'flugverfuegbarkeit.resetEditedFilter';

export const SET_PAX_FILTER = 'flugverfuegbarkeit.setPaxFilter';
export const RESET_PAX_FILTER = 'flugverfuegbarkeit.resetPaxFilter';

export const RESET_REISE_FILTER = 'flugverfuegbarkeit.resetReiseFilter';
export const SET_REISE_FILTER = 'flugverfuegbarkeit.setReiseFilter';

export const SET_START_DATE_FILTER = 'flugverfuegbarkeit.setstartDateFilterFilter';
export const RESET_START_DATE_FILTER = 'flugverfuegbarkeit.resetstartDateFilterFilter';

export const SET_RELEASE_DATE_FILTER = 'flugverfuegbarkeit.setreleaseDateFilterFilter';
export const RESET_RELEASE_DATE_FILTER = 'flugverfuegbarkeit.resetreleaseDateFilterFilter';

export const SET_ABREISE_DATE_FILTER = 'flugverfuegbarkeit.setabreiseDateFilterFilter';
export const RESET_ABREISE_DATE_FILTER = 'flugverfuegbarkeit.resetabreiseDateFilterFilter';

export const SET_AUFPREIS_FILTER = 'flugverfuegbarkeit.setAufpreisFilter';
export const RESET_AUFPREIS_FILTER = 'flugverfuegbarkeit.resetAufpreisFilter';

export const SET_GARANTIERT_FILTER = 'flugverfuegbarkeit.setGarantiertFilter';
export const RESET_GARANTIERT_FILTER = 'flugverfuegbarkeit.resetGarantiertFilter';

export const SET_REISEKUERZEL_FILTER = 'flugverfuegbarkeit.setReisekuerzelFilter';
export const RESET_REISEKUERZEL_FILTER = 'flugverfuegbarkeit.resetReisekuerzelFilter';

export const SET_DEUTSCHER_ABFLUGHAFEN_FILTER = 'flugverfuegbarkeit.setDeutscherAbflughafenFilter';
export const RESET_DEUTSCHER_ABFLUGHAFEN_FILTER = 'flugverfuegbarkeit.resetDeutscherAbflughafenFilter';

export const SET_ABFRAGEZEITPUNKT_RANGE_FILTER = 'flugverfuegbarkeit.setAbfragezeitpunktRangeFilter';
export const RESET_ABFRAGEZEITPUNKT_RANGE_FILTER = 'flugverfuegbarkeit.resetAbfragezeitpunktRangeFilter';

export const SET_BEARBEITUNGSMODUS = 'flugverfuegbarkeit.setBearbeitungsmodus';
export const SET_DETAILDATA = 'flugverfuegbarkeit.setDetailData';

export const SET_ABREISEDATUM_RANGE_FILTER = 'flugverfuegbarkeit.setAbreisedatumRangeFilter';
export const RESET_ABREISEDATUM_RANGE_FILTER = 'flugverfuegbarkeit.resetAbreisedatumRangeFilter';

export const SET_RUECKREISEDATUM_RANGE_FILTER = 'flugverfuegbarkeit.setRueckreisedatumRangeFilter';
export const RESET_RUECKREISEDATUM_RANGE_FILTER = 'flugverfuegbarkeit.resetRueckreisedatumRangeFilter';

export const SET_STATUS_FILTER = 'flugverfuegbarkeit.setStatusFilter';
export const RESET_STATUS_FILTER = 'flugverfuegbarkeit.resetStatusFilter';

export const SET_PRIORITAET_FILTER = 'flugverfuegbarkeit.setPrioritaetFilter';
export const RESET_PRIORITAET_FILTER = 'flugverfuegbarkeit.resetPrioritaetFilter';

export const SET_FEHLERMELDUNG_FILTER = 'flugverfuegbarkeit.setFehlermeldungFilter';
export const RESET_FEHLERMELDUNG_FILTER = 'flugverfuegbarkeit.resetFehlermeldungFilter';

export const SET_FLUGSTAMM_HINFLUG_FILTER = 'flugverfuegbarkeit.setFlugstammHinflugFilter';
export const RESET_FLUGSTAMM_HINFLUG_FILTER = 'flugverfuegbarkeit.resetFlugstammHinflugFilter';

export const SET_COUNTRY_FILTER = 'flugverfuegbarkeit.setCountryFilter';
export const RESET_COUNTRY_FILTER = 'flugverfuegbarkeit.resetCountryFilter';

export const SET_REGIONSMANAGER_FILTER = 'flugverfuegbarkeit.setRegionsmanagerFilter';
export const RESET_REGIONSMANAGER_FILTER = 'flugverfuegbarkeit.resetRegionsmanagerFilter';

export const SET_ZIELLAND_ABFLUGHAFEN_FILTER = 'flugverfuegbarkeit.setZiellandAbflughafenFilter';
export const RESET_ZIELLAND_ABFLUGHAFEN_FILTER = 'flugverfuegbarkeit.resetZiellandAbflughafenFilter';

export const SET_ZIELLAND_ZIELFLUGHAFEN_FILTER = 'flugverfuegbarkeit.setZiellandZielhafenFilter';
export const RESET_ZIELLAND_ZIELFLUGHAFEN_FILTER = 'flugverfuegbarkeit.resetZiellandZielhafenFilter';

export const SET_TRANSFERSZENARIO_FILTER = 'flugverfuegbarkeit.setTransferszenarioFilter';
export const RESET_TRANSFERSZENARIO_FILTER = 'flugverfuegbarkeit.resetTransferszenarioFilter';

export const SET_REGION_FILTER = 'flugverfuegbarkeit.setRegionFilter';
export const RESET_REGION_FILTER = 'flugverfuegbarkeit.resetRegionFilter';

export const SET_BEARBEITUNGS_FILTER = 'flugverfuegbarkeit.setBearbeitungsFilter';
export const RESET_BEARBEITUNGS_FILTER = 'flugverfuegbarkeit.resetBearbeitungsFilter';

export const SET_EXPAND = 'flugverfuegbarkeit.setExpand';
export const RESET_EXPAND_TO_INITIAL = 'flugverfuegbarkeit.resetExpandToInitial';

export const SET_ALL_FILTER_TO_INTIAL = 'flugverfuegbarkeit.setAllFilterToInitial';
export const SET_QUERY_COMPUTE = 'flugverfuegbarkeit.setQueryCompute';
export const RESET_QUERY_COMPUTE = 'flugverfuegRESET_LAST_FIVE_ABFRAGENbarkeit.resetQueryCompute';

export const GET_SINGLE_FLUGVERFUEGBARKEIT = 'flugverfuegbarkeit.getSingleFlugverfuegbarkeit';
export const GET_LAST_ABFRAGE = 'flugverfuegbarkeit.getLastAbfrage';
export const RESET_LAST_ABFRAGE = 'flugverfuegbarkeit.resetLastAbfrage';
export const SET_LAST_ABFRAGE = 'flugverfuegbarkeit.setLastAbfrage';

export const SET_LAST_FIVE_ABFRAGEN = 'flugverfuegbarkeit.setLastFiveAbfragen';
export const RESET_LAST_FIVE_ABFRAGEN = 'flugverfuegbarkeit.resetLastFiveAbfragen';

export const SET_LAST_ABFRAGEDATE = 'flugverfuegbarkeit.setLastAbfrageDate';
export const RESET_LAST_ABFRAGEDATE = 'flugverfuegbarkeit.resetLastAbfrageDate';

export const SET_DATAWASCHANGED = 'flugverfuegbarkeit.setDataWasChanged';
export const GET_FLUGPLANUNGSDATA = 'flugverfuegbarkeit.getFlugplanungsData';
export const SET_FLUGPLANUNGSDATA = 'flugverfuegbarkeit.setFlugplanungsData';
export const RESET_FLUGPLANUNGSDATA = 'flugverfuegbarkeit.resetFlugplanungsData';

export const GET_INFO_DATA = 'flugverfuegbarkeit.getInfoData';
export const SET_IS_INFO_LOADING = 'flugverfuegbarkeit.setIsInfoLoading';

export const SET_ONLY_NON_ACCEPTED_FLUGVERFUEGBARKEIT =
  'flugverfuegbarkeit.setOnlyNonAcceptedFlugverfuebarkeit';

const state = () => ({
  appliedFlugverfuegbarkeitenFilter: null,
  flugverfuegbarkeit: [],
  top: 100,
  skip: 0,
  orderBy: null,
  count: null,
  isInitiallyLoading: true,
  isBusy: true,
  isLastAbfrageLoading: false,
  isFlugplanungsdatenLoading: false,
  isInfoLoading: false,
  lastAbfrage: {},
  lastFiveAbfragen: [],
  detailData: {},
  bearbeitung: {
    dataWasChanged: false,
    bearbeitungsModus: false,
  },
  flugplanungsData: {},
  filter: {
    lastAbfrageDate: null,
    reisekuerzelFilter: null,
    deutscherAbflughafenFilter: [],
    abfragezeitpunktRangeFilter: {},
    status: [],
    fehlermeldung: null,
    flugstammHinflug: null,
    ziellandAbflughafen: null,
    ziellandZielflughafen: null,
    abreisedatumRange: {},
    rueckreisedatumRange: {},
    prioritaet: [],
    garantiert: [],
    transferszenario: [],
    region: [],
    regionsmanager: [],
    country: [],
    aufpreis: [],
    reisen: [],
    edited: null,
    bearbeitungsFilter: [],
  },
  expand: {
    Reisetermin: {
      expand: { reise: {}, pax: {}, metadata: {}, zimmerkontingente: {} },
    },
    aufgaben: { orderBy: ['faelligkeitsdatum'] },
    abfragefehler: {},
  },
  compute: null,
});
const initialState = { ...state() };

const actions = {
  [GET_INFO_DATA](context, fvcId) {
    context.commit(SET_IS_INFO_LOADING, true);
    return apiService
      .get(`flugverfuegbarkeit/${fvcId}/info`)
      .then(response => {
        const infoData = response.data.result.info;
        context.commit(SET_DETAILDATA, { ...context.state.detailData, info: infoData });
      })
      .catch(error => error)
      .finally(() => {
        context.commit(SET_IS_INFO_LOADING, false);
      });
  },
  [GET_FLUGPLANUNGSDATA](context, reiseterminkuerzel) {
    context.commit(SET_FLUGPLANUNGSDATEN_IS_LOADING, true);

    const flugplanungAPIURL = reiseterminkuerzel =>
      `https://api.skr.de/get-flugplanung-data?code=DvUysPkfvAcm1emL4gRAKWDJC4HC__Eoyqrdn5enV2JfAzFuzVK6VA==&reiseterminkuerzel=${reiseterminkuerzel}`;
    const reqURL = flugplanungAPIURL(reiseterminkuerzel);
    return apiService
      .get(reqURL)
      .then(response => {
        context.commit(SET_FLUGPLANUNGSDATA, response.data.data);
      })
      .finally(() => {
        context.commit(SET_FLUGPLANUNGSDATEN_IS_LOADING, false);
      });
  },
  [GET_SINGLE_FLUGVERFUEGBARKEIT](context, id) {
    context.commit(SET_FLUGVERFUEGBARKEIT_IS_BUSY, true);

    return odataService
      .getFlugverfuegbarkeit({
        filter: 'id eq ' + id,
        expand: context.state.expand,
      })
      .then(response => response.data[0])
      .catch(error => error)
      .finally(() => {
        context.commit(SET_FLUGVERFUEGBARKEIT_IS_BUSY, false);
      });
  },
  [GET_LAST_ABFRAGE](context, filter) {
    const {
      reiseterminkuerzel,
      deutscherAbflughafenIataCode,
      formattedAbfragedatum,
      zielflughafenIataCode,
      abflughafenIataCode,
      zimmertyp,
      verpflegung,
    } = filter;
    context.commit(SET_LAST_ABFRAGE_IS_LOADING, true);
    const filterString = `reisetermin/reiseterminkuerzel eq '${reiseterminkuerzel}' and 
                          deutscherAbflughafenIataCode eq '${deutscherAbflughafenIataCode}' and
                          abfragedatum le ${formattedAbfragedatum} and
                          zielflughafenIataCode eq '${zielflughafenIataCode}' and
                          abflughafenIataCode eq '${abflughafenIataCode}' and
                          zimmertyp eq '${zimmertyp}' and
                          verpflegung eq '${verpflegung}'`;
    odataService
      .getFlugverfuegbarkeit({
        ...{ filter: filterString },
        ...(context?.state?.expand && { expand: context.state.expand }),
        ...{ top: 5 },
        ...{ orderBy: 'abfragedatum desc' },
      })
      .then(response => {
        context.commit(SET_LAST_ABFRAGE, response?.data[1] || {});
        context.commit(SET_LAST_FIVE_ABFRAGEN, response.data);
        context.commit(SET_LAST_ABFRAGE_IS_LOADING, false);
      })
      .catch(_ => context.commit(SET_LAST_ABFRAGE_IS_LOADING, false));
  },
  [GET_FLUGVERFUEGBARKEIT](context, request = null) {
    context.commit(SET_FLUGVERFUEGBARKEIT_IS_BUSY, true);
    context.commit(SET_FLUGVERFUEGBARKEIT_COUNT, null);
    const odataFilterFromAgGridRequest = getOdataFilterFromAgGridRequest(context.state, request);

    return odataService
      .getFlugverfuegbarkeit({
        filter: odataFilterFromAgGridRequest,
        ...(context?.state?.compute && { compute: context.state.compute }),
        ...(context?.state?.expand && { expand: context.state.expand }),
        ...(context?.state?.top && { top: context.state.top }),
        ...(context?.state?.skip && { skip: context.state.skip }),
        ...(context?.state?.orderBy && { orderBy: context.state.orderBy }),
        count: true,
      })
      .then(response => {
        context.commit(SET_IS_INITIALLY_LOADING, false);

        context.commit(SET_FLUGVERFUEGBARKEIT, response.data || []);
        context.commit(SET_FLUGVERFUEGBARKEIT_IS_BUSY, false);
        context.commit(SET_FLUGVERFUEGBARKEIT_COUNT, response.count || 0);
        return response.data;
      });
  },
  [FLUGVERFUEGBARKEIT_SORT_CHANGED](context, { sortContext: sortContext }) {
    let orderBy = convertTableSortToOdata(sortContext.sortBy, sortContext.sortDesc);

    context.commit(SET_FLUGVERFUEGBARKEIT_ORDER_BY, orderBy);
    return context.dispatch(GET_FLUGVERFUEGBARKEIT);
  },
  [RESET_FILTER](context) {
    context.commit(SET_FLUGVERFUEGBARKEIT_SKIP, 0);
    context.commit(SET_ALL_FILTER_TO_INTIAL);
    return context.dispatch(GET_FLUGVERFUEGBARKEIT);
  },
};

const mutations = {
  [SET_LAST_ABFRAGEDATE](state, lastAbfragedate) {
    state.filter.lastAbfrageDate = lastAbfragedate;
  },
  [RESET_LAST_ABFRAGEDATE](state) {
    state.filter.lastAbfrageDate = null;
  },
  [RESET_EXPAND_TO_INITIAL](state) {
    state.expand = initialState.expand;
  },
  [SET_EXPAND](state, expand) {
    state.expand = expand;
  },
  [SET_LAST_ABFRAGE](state, lastAbfrage) {
    state.lastAbfrage = lastAbfrage;
  },
  [RESET_LAST_ABFRAGE](state) {
    state.lastAbfrage = {};
  },
  [SET_LAST_FIVE_ABFRAGEN](state, lastFiveAbfragen) {
    state.lastFiveAbfragen = lastFiveAbfragen;
  },
  [RESET_LAST_FIVE_ABFRAGEN](state) {
    state.lastFiveAbfragen = [];
  },
  [SET_FLUGVERFUEGBARKEIT](state, flugverfuegbarkeit) {
    state.flugverfuegbarkeit = flugverfuegbarkeit;
  },
  [APPEND_FLUGVERFUEGBARKEIT](state, flugverfuegbarkeit) {
    state.flugverfuegbarkeit.push(...flugverfuegbarkeit);
  },
  [SET_FLUGVERFUEGBARKEIT_COUNT](state, count) {
    state.count = count;
  },
  [SET_FLUGVERFUEGBARKEIT_IS_BUSY](state, isBusy) {
    state.isBusy = isBusy;
  },
  [SET_LAST_ABFRAGE_IS_LOADING](state, isLoading) {
    state.isLastAbfrageLoading = isLoading;
  },
  [SET_FLUGPLANUNGSDATEN_IS_LOADING](state, isLoading) {
    state.isFlugplanungsdatenLoading = isLoading;
  },
  [SET_IS_INITIALLY_LOADING](state, isInitiallyLoading) {
    state.isInitiallyLoading = isInitiallyLoading;
  },
  [SET_FLUGVERFUEGBARKEIT_ORDER_BY](state, orderBy) {
    state.orderBy = orderBy;
  },
  [SET_FLUGVERFUEGBARKEIT_SKIP](state, skip) {
    state.skip = skip;
  },
  [SET_FLUGVERFUEGBARKEIT_TOP](state, top) {
    state.top = top;
  },
  [SET_QUERY_COMPUTE](state, compute) {
    state.compute = compute;
  },
  [RESET_QUERY_COMPUTE](state, compute) {
    state.compute = compute;
  },

  [SET_ALL_FILTER_TO_INTIAL](state) {
    state.filter.reisekuerzelFilter = null;
    state.filter.deutscherAbflughafenFilter = [];
  },

  [SET_BEARBEITUNGS_FILTER](state, bearbeitungStatus) {
    state.filter.bearbeitungsFilter = bearbeitungStatus;
  },
  [RESET_BEARBEITUNGS_FILTER](state) {
    state.filter.bearbeitungsFilter = [];
  },

  // Reisekuerzel Filter
  [SET_REISEKUERZEL_FILTER](state, filter) {
    state.filter.reisekuerzelFilter = filter;
  },
  [RESET_REISEKUERZEL_FILTER](state) {
    state.filter.reisekuerzelFilter = null;
  },
  // Deutscher Abflughafen IATA Code Filter
  [SET_DEUTSCHER_ABFLUGHAFEN_FILTER](state, filter) {
    state.filter.deutscherAbflughafenFilter = filter;
  },
  [RESET_DEUTSCHER_ABFLUGHAFEN_FILTER](state) {
    state.filter.deutscherAbflughafenFilter = [];
  },

  [SET_ABFRAGEZEITPUNKT_RANGE_FILTER](state, filter) {
    state.filter.abfragezeitpunktRangeFilter = filter;
  },
  [RESET_ABFRAGEZEITPUNKT_RANGE_FILTER](state) {
    state.filter.abfragezeitpunktRangeFilter = {};
  },

  [SET_ABREISEDATUM_RANGE_FILTER](state, filter) {
    state.filter.abreisedatumRange = filter;
  },
  [RESET_ABREISEDATUM_RANGE_FILTER](state) {
    state.filter.abreisedatumRange = {};
  },

  [SET_RUECKREISEDATUM_RANGE_FILTER](state, filter) {
    state.filter.rueckreisedatumRange = filter;
  },
  [RESET_RUECKREISEDATUM_RANGE_FILTER](state) {
    state.filter.rueckreisedatumRange = {};
  },

  [SET_STATUS_FILTER](state, filter) {
    state.filter.status = filter;
  },
  [RESET_STATUS_FILTER](state) {
    state.filter.status = [];
  },

  [SET_GARANTIERT_FILTER](state, filter) {
    state.filter.garantiert = filter;
  },
  [RESET_GARANTIERT_FILTER](state) {
    state.filter.garantiert = [];
  },

  [SET_PRIORITAET_FILTER](state, filter) {
    state.filter.prioritaet = filter;
  },
  [RESET_PRIORITAET_FILTER](state) {
    state.filter.prioritaet = [];
  },

  [SET_TRANSFERSZENARIO_FILTER](state, filter) {
    state.filter.transferszenario = filter;
  },
  [RESET_TRANSFERSZENARIO_FILTER](state) {
    state.filter.transferszenario = [];
  },
  [SET_AUFPREIS_FILTER](state, filter) {
    state.filter.aufpreis = filter;
  },
  [RESET_AUFPREIS_FILTER](state) {
    state.filter.aufpreis = [];
  },

  // Fehlermeldung Filter
  [SET_FEHLERMELDUNG_FILTER](state, filter) {
    state.filter.fehlermeldung = filter;
  },
  [RESET_FEHLERMELDUNG_FILTER](state) {
    state.filter.fehlermeldung = null;
  },

  // Flugstamm Hinflug Filter
  [SET_FLUGSTAMM_HINFLUG_FILTER](state, filter) {
    state.filter.flugstammHinflug = filter;
  },
  [RESET_FLUGSTAMM_HINFLUG_FILTER](state) {
    state.filter.flugstammHinflug = null;
  },

  // Zielland Zielflughafen Filter
  [SET_ZIELLAND_ZIELFLUGHAFEN_FILTER](state, filter) {
    state.filter.ziellandZielflughafen = filter;
  },
  [RESET_ZIELLAND_ZIELFLUGHAFEN_FILTER](state) {
    state.filter.ziellandZielflughafen = null;
  },

  // Zielland Abflughafen Filter
  [SET_ZIELLAND_ABFLUGHAFEN_FILTER](state, filter) {
    state.filter.ziellandAbflughafen = filter;
  },
  [RESET_ZIELLAND_ABFLUGHAFEN_FILTER](state) {
    state.filter.ziellandAbflughafen = null;
  },
  [SET_REGION_FILTER](state, filter) {
    state.filter.region = filter;
  },
  [RESET_REGION_FILTER](state) {
    state.filter.region = [];
  },
  [SET_COUNTRY_FILTER](state, filter) {
    state.filter.country = filter;
  },
  [RESET_COUNTRY_FILTER](state) {
    state.filter.country = [];
  },
  [SET_BEARBEITUNGSMODUS](state, bearbeitungsModus) {
    state.bearbeitung.bearbeitungsModus = bearbeitungsModus;
  },
  [SET_DETAILDATA](state, detailData) {
    state.detailData = detailData;
  },
  [SET_DATAWASCHANGED](state, isChanged) {
    state.bearbeitung.dataWasChanged = isChanged;
  },
  [SET_FLUGPLANUNGSDATA](state, flugplanungsData) {
    state.flugplanungsData = flugplanungsData;
  },
  [RESET_FLUGPLANUNGSDATA](state) {
    state.flugplanungsData = {};
  },

  [SET_REISE_FILTER](state, reisen) {
    state.filter.reisen = reisen;
  },
  [RESET_REISE_FILTER](state) {
    state.filter.reisen = [];
  },
  [SET_REGIONSMANAGER_FILTER](state, regionsmanager) {
    state.filter.regionsmanager = regionsmanager;
  },
  [RESET_REGIONSMANAGER_FILTER](state) {
    state.filter.regionsmanager = [];
  },
  [SET_EDITED_FILTER](state, edited) {
    state.filter.edited = edited;
  },
  [RESET_EDITED_FILTER](state) {
    state.filter.edited = null;
  },
  [SET_IS_INFO_LOADING](state, isLoading) {
    state.isInfoLoading = isLoading;
  },
};

const getOdataFilterFromAgGridRequest = (state, request) => {
  const filterKeys = Object.keys(request.filterModel);

  let filters = filterKeys.map(key => {
    const formattedKey = key.replaceAll('.', '/');
    if (request.filterModel[key].filterType === 'text') {
      if (formattedKey === 'id') {
        return { [formattedKey]: parseInt(request.filterModel[key].filter) };
      } else if (formattedKey === 'reisekuerzelPrefilled') {
        return { 'reisetermin/reise/reisekuerzel': { contains: request.filterModel[key].filter } };
      }
      return { [formattedKey]: { startswith: request.filterModel[key].filter } };
    } else if (request.filterModel[key].filterType === 'set') {
      if (key === 'status') {
        const statusSelected = request.filterModel[key].values.map(item => {
          if (item.includes('Verfuegbar<200')) {
            return "'Verfuegbar' and aufpreis le 200";
          }
          if (item.includes('Verfuegbar>200')) {
            return "'Verfuegbar' and aufpreis gt 200";
          } else return "'" + item + "'";
        });
        return '(' + statusSelected.map(value => `(status eq ${value})`).join(' or ') + ')';
      } else if (key === 'farben') {
        const bearbeitungsStatusConverter = [
          { bearbeitungsStatus: 'Akzeptiert', odataFilter: "'Akzeptiert'" },
          {
            bearbeitungsStatus: 'VerfuegbarkeitGeschaffen',
            odataFilter: "'VerfuegbarkeitGeschaffen'",
          },
          {
            bearbeitungsStatus: 'VerfuegbarkeitNichtGeschaffen',
            odataFilter: "'VerfuegbarkeitNichtGeschaffen'",
          },
          {
            bearbeitungsStatus: 'null',
            odataFilter: 'null',
          },
        ];
        return (
          'bearbeitungStatus in (' +
          request.filterModel[key].values.map(
            filterValue =>
              bearbeitungsStatusConverter.find(item => item.bearbeitungsStatus === filterValue).odataFilter
          ) +
          ')'
        );
      } else if (key === 'bearbeitung') {
        const bearbeitungsValues = request.filterModel[key].values;
        const converter = [
          {
            bearbeitung: 'Automatisch bearbeitet',
            odataFilter: '(lastModifiedUserId eq null and bearbeitungStatus ne null)',
          },
          {
            bearbeitung: 'Manuell bearbeitet',
            odataFilter: '(lastModifiedUserId ne null and bearbeitungStatus ne null)',
          },
        ];
        return (
          '(' +
          bearbeitungsValues
            .map(value => converter.find(item => item.bearbeitung === value)?.odataFilter)
            .join(' or ') +
          ')'
        );
      } else if (key === 'regionsmanager') {
        const selectedRegionsmanagerKuerzel = request.filterModel[key].values;
        const allReisenForSelectionRegionmanagers = selectedRegionsmanagerKuerzel
          .map(regionsmanagerKuerzel => getReisenOfRegionsmanager(regionsmanagerKuerzel))
          .flat();

        return { 'reisetermin/reise/reisekuerzel': { in: allReisenForSelectionRegionmanagers } };
      } else if (key === 'aufpreis') {
        const values = request.filterModel[key].values;
        const mapper = [
          { filter: '<0€', formattedFilter: '(aufpreis lt 0)' },
          { filter: '0€', formattedFilter: '(aufpreis eq 0)' },
          { filter: '0-100€', formattedFilter: '(aufpreis gt 0 and aufpreis le 100)' },
          { filter: '100-200€', formattedFilter: '(aufpreis gt 100 and aufpreis le 200)' },
          { filter: '>200€', formattedFilter: '(aufpreis gt 200)' },
        ];
        return (
          '(' +
          values.map(value => mapper.find(item => item.filter === value)?.formattedFilter).join(' or ') +
          ')'
        );
      } else if (key === 'reisetermin.reise.region') {
        const regionValues = request.filterModel[key].values
          .map(value => (value === 'Fern' ? fernRegions : euRegions))
          .flat();
        return { [formattedKey]: { in: regionValues } };
      } else {
        return { [formattedKey]: { in: request.filterModel[key].values } };
      }
    } else if (request.filterModel[key].filterType === 'date') {
      const { dateFrom, dateTo } = request.filterModel[key];
      const utcAbreisedatum = new Date(
        Date.UTC(+dateFrom.substring(0, 4), +dateFrom.substring(5, 7) - 1, +dateFrom.substring(8, 10))
      );
      const utcEnddatum = add(
        new Date(Date.UTC(+dateTo.substring(0, 4), +dateTo.substring(5, 7) - 1, +dateTo.substring(8, 10))),
        { hours: 23, minutes: 59, seconds: 59 }
      );
      return {
        [formattedKey]: {
          ge: utcAbreisedatum,
          le: utcEnddatum,
        },
      };
    }
    return;
  });
  // if checkbox is ticked only show lastAbfrage
  if (state.filter.lastAbfrageDate !== null && !Object.keys(request.filterModel).includes('id')) {
    filters = [...filters, { abfragedatum: { ge: new Date(state.filter.lastAbfrageDate) } }];
  }
  // if bearbeitung manuell / automatisch is ticked
  if (state.filter.edited) {
    filters = [...filters, state.filter.edited];
  }
  return filters;
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
